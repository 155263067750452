import React, {useState} from 'react';

const Create = () => {
    const [name, setName] = useState<string>("")
    const [amount, setAmount] = useState<string>("")
    const [limits, setlimits] = useState<string>("")
    const [decrement, setDecrement] = useState<string>("")


    const addMedication = async () => {
        let obj = [{
            name: name,
            amount: parseInt(amount),
            limits: parseInt(limits),
            needed: false,
            decrement: parseInt(decrement)
        }]

        const resp = await fetch('/meds', {
            method: "POST",
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    return (
        <div className={"App"}>

        <div className={"container"}>
            <div className={"input-container span"}>
                <div className={"input-nest"}>
                    <label htmlFor={"name"}>Name</label>
                    <input  onChange={(e) => setName(e.target.value)} id={"name"} type={"text"}/>
                    <label htmlFor={"amount"}>Amount</label>
                    <input onChange={(e) => setAmount(e.target.value)} id={"amount"} type={"text"}/>
                    <label htmlFor={"limits"}>limits</label>
                    <input onChange={(e) => setlimits(e.target.value)} id={"limits"} type={"text"}/>
                    <label htmlFor={"decrement"}>Decrement</label>
                    <input onChange={(e) => setDecrement(e.target.value)} id={"decrement"} type={"text"}/>
                </div>
                <button className={"submitButton"} onClick={addMedication}>Add Medication</button>
            </div>
        </div>
</div>
    );
};

export default Create;