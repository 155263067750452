import React from 'react';

const Printer = ({globalState}: any) => {

    let listOfItems = globalState.filter((item: any) => (item.amount < item.limits) || item.needed == true)

    console.log(listOfItems)

    let elements = listOfItems.map((item: any) => {
        return (
                <p>{item.name} | {item.amount}/{item.limits}</p>
        )
    })

    const printList = async () => {
        const resp = await fetch('/needed', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    return (
        <div>
            <div className={"printer_container"}>
                <div className={"p_container"}>
                    {elements}
                    <button type={"button"} onClick={printList} className={"submitButton half"}>Print List</button>
                </div>
            </div>
        </div>
    );
};

export default Printer;