import React, {ReactNode, useEffect, useState} from 'react';
import './App.css';
import {futimes} from "fs";
// @ts-ignore
import {BrowserRouter, Link, Route, Routes, RouteComponentProps, HashRouter} from "react-router-dom";
import Create from "./components/Create";
import Printer from "./components/Printer";


interface Medication {
    name: string,
    amount: number,
    limits: number,
    needed: boolean,
    decrement: number
}

interface ButtonNumber {
    number: number
}



interface inputMethod extends Medication {
    handleStateUpdate: any
}


const IncrementalButton = (obj: ButtonNumber) => {
    return (
        <div>
            <button className={"button"}>
                +{obj.number}
            </button>
        </div>
    )
}

const InputArea = (data: inputMethod) => {
    const [value, setValue] = useState<number>(data.amount)
    const [obj, setObj] = useState({
        name: data.name,
        amount: data.amount,
        limits: data.limits,
        needed: data.needed,
        decrement: data.decrement
    })


    const doTheUpdate = (obj: Medication) => {
        data.handleStateUpdate(obj)
    }

    const handleUpdate = (increase: any) => {
        console.log(increase)
        setValue(isNaN(increase) ? 0 : increase)
        let returnObj: Medication = {
            name: obj.name,
            amount: increase,
            limits: obj.limits,
            needed: false,
            decrement: obj.decrement
        }
        setObj(returnObj)
        doTheUpdate(returnObj)
    }




    return (
        <div className={"input-container"}>
            <div className={"input-nest"}>
                <label htmlFor={data.name}>{data.name}</label>
                <input value={value} onChange={(e) => handleUpdate(parseInt(e.target.value))} id={data.name} type={"text"}/>
            </div>
            <div className={"buttonContainer"}>
                <button onClick={(e) => handleUpdate(value + 7)} className={"button"}>+7</button>
                <button onClick={(e) => handleUpdate(value + 14)} className={"button"}>+14</button>
            </div>
        </div>

    )
}


const Home = ({handleStateUpdate, globalState}: any) => {

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // console.log(e.target)
    }



    const writeToDb = async () => {
        console.log(JSON.stringify(globalState))
        const resp = await fetch('/meds', {
            method: "POST",
            body: JSON.stringify(globalState),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    const allInputs = globalState.map((item: Medication) => {
        // @ts-ignore
        return <InputArea key={item.name} name={item.name} amount={item.amount} needed={item.needed} limits={item.limits} decrement={item.decrement}
                          handleStateUpdate={handleStateUpdate}/>
    })

    const decrementAll = async () => {
        await fetch('/decrementAll', {method: "POST"})
            .then((resp) => {
                if (resp.status == 200){
                    window.location.reload()
                }
            })
    }
    return (
        <div className="App">
            <h1>Medication Tracker</h1>
            <div className={"container"}>
                {allInputs}
            </div>
            <div className={"buttonDiv"}>
                <a className={"submitButton"} href={"#create"}>Add New</a>
                <button className={"submitButton"} onClick={decrementAll}>Decrement All</button>
                <a className={"submitButton"} href={"#printer"}>Go to printer</a>
                <button className={"submitButton"} type={"button"} onClick={writeToDb}>Update</button>
            </div>s
        </div>
    );
}

const App = () => {
    // @ts-ignore
    const [globalState, setGlobalState] = useState<Array<Medication>>([])

    useEffect(() => {
        fetch('/meds').then(resp => resp.json()).then(data => setGlobalState(data.sort((a: Medication, b: Medication) => a.name.localeCompare(b.name))))
    }, [])

    const handleStateUpdate = (obj: Medication) => {
        let cloned = structuredClone(globalState)
        let itemToReplace = cloned.find((item: Medication) => item.name === obj.name)
        Object.assign(itemToReplace, obj)
        setGlobalState(cloned)
        console.log(cloned)
    }

    return (
        <HashRouter>
            <Routes>
                <Route path={"/"} element={<Home globalState={globalState} handleStateUpdate={handleStateUpdate}/>}></Route>
                <Route path={"/create"} element={<Create/>}></Route>
                <Route path={"/printer"} element={<Printer globalState={globalState} />}></Route>
            </Routes>
        </HashRouter>
    )
}

export default App;